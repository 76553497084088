import { NavLink } from "react-router-dom";

const Header = () => {
    return (
        <header className="search-panel__container">
            <nav>
                <ul>
                    <li><NavLink to="/">Пользователи</NavLink></li>
                    <li><NavLink to="/wallets">Кошельки</NavLink></li>
                    <li><NavLink to="/tasks">Задания</NavLink></li>
                    <li><NavLink to="/lessons">Библиотека</NavLink></li>
                    <li><NavLink to="/lists">Списки</NavLink></li>
                    <li><NavLink to="/statistic">Статистика</NavLink></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;