import useEntity from '../../hooks/useEntity';
import AddForm from '../../widgets/AddForm/AddForm';
import List from '../../widgets/List/List';
import Loader from '../../UI/Loader/Loader';
import CustomList from '../../entities/CustomList/CustomList';
import Error from '../../UI/Error/Error';
import AcceptPopap from '../../feaches/AcceptPopap/AcceptPopap';

const CustomLists = () => {
    const {
        items: lists,
        isLoading,
        error,
        addItemHandler,
        deleteItemHandler,
        openPopapHandler,
        closePopapHandler,
        popapId
    } = useEntity('list', 'lists');

    const listField = [
        { name: 'name', type: 'text', placeholder: 'Название', required: true },
    ];

    return (
        <div className="list__wrapper">
            <h1>Списки: </h1>
            <AddForm fields={listField} onAdd={addItemHandler} buttonText={'Добавить список'} />
            {error && <Error message={error} />}
            {isLoading && <Loader />}
            <List elements={lists} onDelete={openPopapHandler} onReturn={null} Entitie={CustomList} />
            {popapId !== 0 && <AcceptPopap handleAccept={deleteItemHandler} handleClosePopap={closePopapHandler} />}
        </div>
    );
}

export default CustomLists;
