const UserDataList = ({ list, title, ifNullText }) => {
    return (
        <div className="user-data__wrapper">
            <div className="title">{title}</div>
            <div className="user-data__list">
                {list.length > 0 ? list : (
                    <div className="no-data">{ifNullText}</div>
                )}
            </div>
        </div>
    )
}

export default UserDataList;