import useEntity from '../../hooks/useEntity';
import Error from '../../UI/Error/Error';
import Loader from '../../UI/Loader/Loader';
import List from '../../widgets/List/List';
import AddForm from '../../widgets/AddForm/AddForm';
import Lesson from '../../entities/Lesson/Lesson';
import AcceptPopap from '../../feaches/AcceptPopap/AcceptPopap';
import { lessonTypes } from '../../entities/Lesson/LessonTypes';

const Lessons = () => {
    const {
        items: lessons,
        filter,
        setFilter,
        isLoading,
        error,
        addItemHandler,
        deleteItemHandler,
        hideItemHandler,
        returnItemHandler,
        openPopapHandler,
        closePopapHandler,
        popapId
    } = useEntity('lesson', 'lessons', true);

    const lessonFields = [
        { name: 'name', type: 'text', placeholder: 'Название', required: true },
        { name: 'text', type: 'textarea', placeholder: 'Описание урока', required: true },
        { name: 'points', type: 'number', placeholder: 'Количество баллов', required: true },
        { name: 'type_id', type: 'select', placeholder: 'Тип урока', required: true, options: lessonTypes },
        { name: 'image', type: 'file', placeholder: 'Изображение', required: false }
    ];

    return (
        <div className="tasks-list__wrapper">
            <h1>Список уроков: </h1>
            <AddForm onAdd={addItemHandler} fields={lessonFields} buttonText={'Добавить урок'} />
            {isLoading && <Loader />}
            {error && <Error message={error}/>}
            <List filter={filter} setFilter={setFilter} elements={lessons} onHide={hideItemHandler} onDelete={openPopapHandler} onReturn={returnItemHandler} Entitie={Lesson} />
            {popapId !== 0 && <AcceptPopap handleAccept={deleteItemHandler} handleClosePopap={closePopapHandler}/>}
        </div>
    );
}

export default Lessons;
