
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useHttp from '../../hooks/useHttp';
import UserList from '../../widgets/User/UserList/UserList';
import AddForm from '../../widgets/AddForm/AddForm';

const CustomListData = () => {
    const { id } = useParams();
    const { isLoading, error, fetchData } = useHttp();
    const [users, setUsers] = useState([]);

    const fetchLists = () => {
        fetchData(`/api/list/${id}`, null, 'GET')
            .then(res => setUsers(res))
            .catch(error => console.error("Error adding users:", error));
    }

    const listField = [
        { name: 'text', type: 'textarea', placeholder: 'Напишите текст', required: true },
    ]

    const handleDeleteFromList = (e, userId) => {
        e.preventDefault();
        fetchData(`/api/list/${id}/delete`, { userId }, 'POST')
            .then(() => fetchLists())
            .catch(error => console.error('Error delete user to list: ', error));
    }

    const handleBroadcast = ({text}) => {
        fetchData(`/api/list/${id}/broadcast`, { text }, 'POST')
            .catch(error => console.error('Error fetching broadcast: ', error));
    }

    useEffect(() => {
        fetchLists();
    }, []);

    return (
        <div className="data-page">
            <h1>Элементы списка:</h1>
            <AddForm fields={listField} onAdd={handleBroadcast} buttonText={'Разослать'}/>
            <UserList users={users} isLoading={isLoading} error={error} handleDeleteFromList={handleDeleteFromList}/>
        </div>
    );
};

export default CustomListData;
