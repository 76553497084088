import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import { useParams } from "react-router-dom";
import User from "../../entities/User/User";
import Loader from "../../UI/Loader/Loader";
import Error from "../../UI/Error/Error";
import Task from "../../entities/Task/Task";
import UserDetails from "../../widgets/User/UserDetails/UserDetails";
import UserDataList from '../../widgets/User/UserDataList/UserDataList';

const UserData = () => {
    const {isLoading, error, fetchData} = useHttp();
    const [user, setUser] = useState({});
    const [referrals, setReferrals] = useState([]);
    const [tasks, setTasks] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        fetchData(`/api/user/${id}`, null, 'GET')
            .then(res => res.length > 0 ? setUser(res[0]) : setUser({}))
            .catch(error => console.error("Error fetching user:", error));

        fetchData(`/api/user/${id}/referrals`, null, 'GET')
            .then(res => setReferrals(res))
            .catch(error => console.error("Error fetching referrals:", error));

        fetchData(`/api/tasks/completed/${id}`, null, 'GET')
            .then(res => setTasks(res))
            .catch(error => console.error("Error fetching tasks:", error));
    }, [fetchData, id]);

    const referralsList = referrals.map((referral) => <User key={referral.id} {...referral}/>);
    const tasksList = tasks.map(task => <Task key={task.id} {...task}/>);

    if (isLoading) {
        return <Loader />;
    }
    
    if (error) {
        return <Error message={error} />;
    }

    return (
        <div className="user-data">
            <UserDetails 
                user={user}/>

            <UserDataList 
                list={referralsList}
                title="Списко рефералов:"
                ifNullText="Нет рефералов."/>

            <UserDataList 
                list={tasksList}
                title="Списко выполненных заданий:"
                ifNullText="Нет выполненных заданий."/>
        </div>
    );
};

export default UserData;
