import { NavLink } from "react-router-dom";

import icon from '../../assets/images/delete_icon.svg';

const CustomList = ({id, name, onDelete}) => {
    return (
        <div className="table__item">
            <div className="item">{id}</div>
            <NavLink to={`/list/${id}`} className="item">{name}</NavLink>
            <button className="delete_btn" onClick={() => onDelete(id)}><img src={icon} alt="delete"/></button>
        </div>
    )
}

export default CustomList;