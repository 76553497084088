import useEntity from '../../hooks/useEntity';
import Error from '../../UI/Error/Error';
import Loader from '../../UI/Loader/Loader';
import AddForm from '../../widgets/AddForm/AddForm';
import List from '../../widgets/List/List';
import Task from '../../entities/Task/Task';
import AcceptPopap from '../../feaches/AcceptPopap/AcceptPopap';

const Tasks = () => {
    const {
        items: tasks,
        filter,
        setFilter,
        isLoading,
        error,
        addItemHandler,
        deleteItemHandler,
        hideItemHandler,
        returnItemHandler,
        openPopapHandler,
        closePopapHandler,
        popapId
    } = useEntity('task', 'tasks');

    const taskFields = [
        { name: 'name', type: 'text', placeholder: 'Название', required: true },
        { name: 'text', type: 'textarea', placeholder: 'Описание задания', required: true },
        { name: 'points', type: 'number', placeholder: 'Количество баллов', required: true }
    ];

    return (
        <div className="tasks-list__wrapper">
            <h1>Список заданий: </h1>
            <AddForm onAdd={addItemHandler} fields={taskFields} buttonText={'Добавить задание'} />
            {isLoading && <Loader />}
            {error && <Error message={error}/>}
            <List filter={filter} setFilter={setFilter} elements={tasks} onHide={hideItemHandler} onDelete={openPopapHandler} onReturn={returnItemHandler} Entitie={Task} />
            {popapId !== 0 && <AcceptPopap handleAccept={deleteItemHandler} handleClosePopap={closePopapHandler}/>}
        </div>
    );
}

export default Tasks;
