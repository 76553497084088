import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../UI/Loader/Loader';
import Error from '../../UI/Error/Error';

import useHttp from '../../hooks/useHttp';
import { lessonTypes } from '../../entities/Lesson/LessonTypes';

import './LessonDataPage.css';

const LessonDataPage = () => {
    const { id } = useParams();
    const { isLoading, error, fetchData } = useHttp();
    const [lesson, setLesson] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        fetchData(`/api/lesson/${id}`, null, 'GET')
            .then(res => res.length > 0 ? setLesson(res[0]) : setLesson({}))
            .catch(error => console.error("Error fetching lesson:", error));
    }, [id, fetchData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLesson({ ...lesson, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setLesson({ ...lesson, photo_path: URL.createObjectURL(file) });
    };

    const handleDeleteImage = () => {
        setLesson({...lesson, photo_path: null})
    }

    const handleSaveData = async (e) => {
        e.preventDefault();
        let result;

        if (selectedFile) {
            const formData = new FormData();
            formData.append('image', selectedFile);

            result = await fetchData('/api/lessons/photo', formData, 'POST', false);
        }

        await fetchData(`/api/lesson/${id}`, {...lesson, photo_path: result?.image ? result?.image : (lesson.photo_path || null)}, 'PUT')
            .catch(error => console.error("Error updating lesson:", error));
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    return (
        <div className="data-page">
            <h1>Информация об уроке:</h1>
            <div className="element-detail">
                {lesson.photo_path && 
                <div className="item">
                    <img src={lesson.photo_path} alt='lesson'/>
                </div>}
                <div className="item choose-image">
                    <input type="file" name="image" onChange={handleFileChange}/>
                    { lesson.photo_path && <button onClick={handleDeleteImage}>Убрать</button> }
                </div>
                <div className="item"><strong>ID:</strong> {lesson.id}</div>
                <div className="item"><strong>Тип:</strong> {lessonTypes[lesson.type_id]}</div>
                <div className="item"><strong>Статус:</strong> {lesson.is_deleted ? 'Скрыт' : 'Активен'}</div>
                <div className="item">
                    <label><strong>Название:</strong></label>
                    <input type="text" name="name" value={lesson.name || ''} onChange={handleInputChange} />
                </div>
                <div className="item">
                    <label><strong>Текст:</strong></label>
                    <textarea name="text" value={lesson.text || ''} onChange={handleInputChange}></textarea>
                </div>
                <div className="item">
                    <label><strong>Количество очков:</strong></label>
                    <input type="text" name="points" value={lesson.points || ''} onChange={handleInputChange} />
                </div>
                <button onClick={handleSaveData}>Сохранить</button>
            </div>
        </div>
    );
};

export default LessonDataPage;
