const Popap = ({ children, handleClosePopap }) => {
    return (
        <div className="popup">
            <div className="popup__content">
                <button className="popup__close" onClick={(e) => handleClosePopap(e)}>×</button>
                <div className="popap__data">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Popap;