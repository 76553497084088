import Popap from "../../UI/Popap/Popap";

const AcceptPopap = ({ handleClosePopap, handleAccept }) => {
    return (
        <Popap handleClosePopap={handleClosePopap}>
            <h3>Вы уверены?</h3>
            <div className="btns__wrapper">
                <button onClick={handleAccept}>Да</button>
                <button onClick={handleClosePopap}>Нет</button>
            </div>
        </Popap>
    );
}

export default AcceptPopap;