const UserDetails = ({ user }) => {
    const totalPoints = user.task_points + user.ref_points + user.lesson_points;

    return (
        <div className="user-data__wrapper">
            <h1 className="title">Информация о пользователе:</h1>
            <div className="user-data__container">
                <div className="item">{user.id}</div>
                <div className="item"><span>Имя: </span> {user.name}</div>
                <div className="item"><span>Id приглосителя: </span> {user.ref_id}</div>
                <div className="item"><span>Кошелек: </span> {user.wallet}</div>
                <div className="item"><span>Очки за задания: </span> {user.task_points}</div>
                <div className="item"><span>Очки за уроки: </span> {user.lesson_points}</div>
                <div className="item"><span>Очки за рефералов: </span> {user.ref_points}</div>
                <div className="item"><span>Общее количество очков: </span> {totalPoints}</div>
            </div>
        </div>
    );
};

export default UserDetails;