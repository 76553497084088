import { useState } from "react";
import { NavLink } from "react-router-dom";

import ChoosePopup from "../../feaches/ChoosePopap/ChoosePopap";

const User = ({id, name, handleDeleteFromList}) => {
    const [popap, setPopap] = useState(false);

    const handleOpenPopap = (e) => {
        e.preventDefault();
        setPopap(true);
    }

    const handleClosePopap = (e) => {
        e.preventDefault();
        setPopap(false);
    }

    return (
        <>
        <NavLink to={"/user/" + id} className="user__wrapper">
            <div className="user">
                <div className="item">{id}</div>
                <div className="item">{name}</div>
                {handleDeleteFromList ? <button onClick={(e) => handleDeleteFromList(e, id)}>Удалить</button> :
                <button onClick={(e) => handleOpenPopap(e)}>Добавить в список</button>}
            </div> 
        </NavLink>
        { popap && <ChoosePopup id={id} handleClosePopap={handleClosePopap} closePopap={setPopap}/> }
        </>
    );
}

export default User;