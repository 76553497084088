import { useState } from "react";
import './AddForm.css';

const AddForm = ({ fields, onAdd, buttonText }) => {
    const initialFormState = fields.reduce((acc, field) => {
        if (field.type === 'select' && field.options.length > 0) {
            acc[field.name] = 0;
        } else {
            acc[field.name] = field.type === 'file' ? { file: null, value: '' } : '';
        }
        return acc;
    }, {});

    const [formData, setFormData] = useState(initialFormState);

    const submitHandler = (e) => {
        e.preventDefault();
        onAdd(formData);
        setFormData(initialFormState);
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({ 
            ...formData, 
            [name]: type === 'file' ? { file: files[0], value: value } : value 
        });

        console.log(formData);
    };

    return (
        <form className="add-form" onSubmit={submitHandler}>
            {fields.map((field) => {
                if (field.type === 'textarea') {
                    return (
                        <textarea
                            key={field.name}
                            name={field.name}
                            placeholder={field.placeholder}
                            value={formData[field.name]}
                            required={field.required}
                            onChange={handleChange}
                        />
                    );
                } else if (field.type === 'select') {
                    return (
                        <select
                            key={field.name}
                            name={field.name}
                            value={formData[field.name]}
                            required={field.required}
                            onChange={handleChange}
                        >
                            {field.options.map((option, i) => (
                                <option key={i} value={i}>{option}</option>
                            ))}
                        </select>
                    );
                } else {
                    return (
                        <input
                            key={field.name}
                            type={field.type}
                            name={field.name}
                            placeholder={field.placeholder}
                            value={field.type === 'file' ? formData[field.name].value : formData[field.name]}
                            required={field.required}
                            onChange={handleChange}
                        />
                    );
                }
            })}
            <button type="submit">{buttonText}</button>
        </form>
    );
};

export default AddForm;
