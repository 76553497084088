import { useState } from "react";
import useHttp from "../../hooks/useHttp";

import './Login.css';

const Login = ({ setAuthenticated }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { fetchData, isLoading, error } = useHttp();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchData('/api/auth', { login, password }, 'POST');
      if (response?.isAdmin) {
        setAuthenticated(true);
      }
    } catch (err) {
      console.error("Authentication failed:", err);
    }
  };

  return (
    
    <div className="login-form__container">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-form__field">
            <label>Login:</label>
            <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} required />
          </div>
          <div className="login-form__field">
            <label>Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <button className="login-form__button" type="submit" disabled={isLoading}>Login</button>
          {error && <p className="login-form__error">{error}</p>}
        </form>
    </div>
  );
};

export default Login;
