import React from 'react';
import './List.css';

const List = ({ elements, filter, setFilter, onDelete = null, onHide = null, onReturn = null, Entitie }) => {
    const hasIsDeletedField = elements.some(elem => elem.hasOwnProperty('is_deleted'));

    const filteredElements = elements.filter(elem => {
        if (filter === 'all') return true;
        if (filter === 'hidden') return elem.is_deleted;
        if (filter === 'visible') return !elem.is_deleted;
        return true;
    });

    const elementsList = filteredElements.map(elem => 
        <Entitie key={elem.id} {...elem} onDelete={onDelete} onHide={onHide} onReturn={onReturn} />
    );

    const getButtonClass = (buttonFilter) => {
        return filter === buttonFilter ? 'active' : '';
    };

    return (
        <div>
            {hasIsDeletedField && (
                <div className="filter-buttons">
                    <button className={getButtonClass('all')} onClick={() => setFilter('all')}>Все</button>
                    <button className={getButtonClass('hidden')} onClick={() => setFilter('hidden')}>Скрытые</button>
                    <button className={getButtonClass('visible')} onClick={() => setFilter('visible')}>Не скрытые</button>
                </div>
            )}
            <div className="elements-list">{elementsList.length > 0 ? elementsList : 'Список пуст'}</div>
        </div>
    );
};

export default List;
