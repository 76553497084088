import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp"

import UserList from '../../widgets/User/UserList/UserList';
import Search from "../../widgets/Search/Search";


const Main = () => {
    const { fetchData, isLoading, error } = useHttp();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchData('/api/users/', null, 'GET')
            .then(res => setUsers(res))
            .catch(error => console.error("Error fetching users:", error));
    }, [fetchData]);

    return (
        <>
            <Search setUsers={setUsers} fetchData={fetchData}/>
            <UserList users={users} isLoading={isLoading} error={error}/>
        </>
    )
}

export default Main;