import { NavLink } from "react-router-dom";
import { lessonTypes } from "./LessonTypes";

import icon from '../../assets/images/delete_icon.svg';

const Lesson = ({ id, name, type_id, is_deleted = null, onDelete = null, onHide = null, onReturn = null }) => {

    return (
        <div className="table__item">
            <div className="item">{id}</div>
            <div className="item">{lessonTypes[type_id]}</div>
            <NavLink to={`/lesson/${id}`} className="item">{name}</NavLink>
            {is_deleted != null && <div className="item">{is_deleted ? 'Удален' : 'Активен'}</div> }
            {(onHide != null && onReturn != null) && (
                is_deleted ? <button onClick={() => onReturn(id)}>Показать</button> :
                <button onClick={() => onHide(id)}>Скрыть</button>
            )}
            {onDelete != null && (
                <button className="delete_btn" onClick={() => onDelete(id)}><img src={icon} alt="delete"/></button>
            )}
        </div>
    )
}

export default Lesson;