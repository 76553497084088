import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./pages/MainPage/Main";
import UserData from "./pages/UserDataPage/UserData";
import WalletList from "./pages/WalletListPage/WalletList";
import Login from "./pages/LoginPage/Login";
import Tasks from "./pages/TasksPage/Tasks";
import TaskDataPage from "./pages/TaskDataPage/TaskDataPage";
import TaskUsers from "./pages/TaskUsersPage/TaskUsers";
import Lessons from "./pages/LessonsPage/Lessons";
import LessonDataPage from "./pages/LessonDataPage/LessonDataPage";
import CustomLists from "./pages/CustomListPage/CustomLists";
import CustomListData from "./pages/CustomListDataPage/CustomListData";
import Statistic from "./pages/StatisticPage/Statistic";

import './assets/css/style.css'

import { AuthProvider, useAuth } from "./context/AppContext";
import Header from "./chunks/header";

const ProtectedRoute = ({ element }) => {
  const { authenticated, setAuthenticated } = useAuth();
  return authenticated ? element : <Login setAuthenticated={setAuthenticated} />;
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="container">
          <Header />
          <Routes>
            <Route path="/" element={<ProtectedRoute element={<Main />} />} />
            <Route path="/user/:id" element={<ProtectedRoute element={<UserData />} />} />
            <Route path="/wallets" element={<ProtectedRoute element={<WalletList />} />} />
            <Route path="/tasks" element={<ProtectedRoute element={<Tasks />} />} />
            <Route path="/task/:id" element={<ProtectedRoute element={<TaskDataPage />} />} />
            <Route path="/task/:id/users" element={<ProtectedRoute element={<TaskUsers />} />} />
            <Route path="/lessons" element={<ProtectedRoute element={<Lessons />}/>} />
            <Route path="/lesson/:id" element={<ProtectedRoute element={<LessonDataPage />}/>} />
            <Route path="/lists" element={<ProtectedRoute element={<CustomLists />}/>} />
            <Route path="/list/:id" element={<ProtectedRoute element={<CustomListData /> }/>}/>
            <Route path="/statistic" element={ <ProtectedRoute element={ <Statistic /> }/> } />
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
