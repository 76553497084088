import { useEffect, useState, useCallback } from "react";
import useHttp from "../../hooks/useHttp";
import Loader from "../../UI/Loader/Loader";
import Error from "../../UI/Error/Error";
import UserDataList from "../../widgets/User/UserDataList/UserDataList";
import { NavLink } from "react-router-dom";

const Statistic = () => {
    const [statistic, setStatistic] = useState({});
    const [topRefs, setTopRefs] = useState([]);
    const [topPoints, setTopPoints] = useState([]);
    const [activeTab, setActiveTab] = useState('topRefs');
    const [monthChecked, setMonthChecked] = useState(false);

    const { isLoading, error, fetchData } = useHttp();

    const fetchStatistics = useCallback(async () => {
        try {
            const res = await fetchData('/api/statistics', null, 'GET');
            setStatistic({
                refs_count: res[0].data,
                users_count: res[1].data,
                complite_tasks_count: res[2].data,
                comlite_lessons_count: res[3].data
            });
        } catch (error) {
            console.error("Error fetching statistics: ", error);
        }
    }, [fetchData]);

    const fetchTopRefs = useCallback(async (month = false) => {
        try {
            const res = await fetchData('/api/statistics/refs', { month }, 'POST');
            setTopRefs(res);
        } catch (error) {
            console.error("Error fetching top refs: ", error);
        }
    }, [fetchData]);

    const fetchTopPoints = useCallback(async (month = false) => {
        try {
            const res = await fetchData('/api/statistics/points', { month }, 'POST');
            setTopPoints(res);
        } catch (error) {
            console.error("Error fetching top points: ", error);
        }
    }, [fetchData]);

    useEffect(() => {
        fetchStatistics();
        fetchTopRefs();
        fetchTopPoints();
    }, [fetchStatistics, fetchTopRefs, fetchTopPoints]);

    useEffect(() => {
        if (activeTab === 'topRefs') {
            fetchTopRefs(monthChecked);
        } else if (activeTab === 'topPoints') {
            fetchTopPoints(monthChecked);
        }
    }, [activeTab, monthChecked, fetchTopRefs, fetchTopPoints]);

    const handleMonthCheck = (e) => {
        setMonthChecked(e.target.checked);
    };

    const handleCreateList = async (topType, list) => {
        try {
            const result = await fetchData(
                '/api/lists/add',
                { name: `Топ по ${topType} на ${new Date().toLocaleDateString()}` },
                'POST'
            );

            if (result?.listId) {
                const { listId } = result;
                const userIds = list.map(item => item.user_id);

                await fetchData(
                    `/api/list/${listId}/add_list`,
                    { userIds },
                    'POST'
                );
            }
        } catch (error) {
            console.error('Error adding users to list: ', error);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    const topPointsList = topPoints.map(user => (
        <div key={user.user_id} className="table__item">
            <div className="item"><span>Место:</span> {user.place}</div>
            <div className="item"><span>Имя:</span> <NavLink to={`/user/${user.user_id}`}>{user.name}</NavLink></div>
            <div className="item"><span>Количество очков:</span> {user.total_points}</div>
        </div>
    ));

    const topRefsList = topRefs.map(ref => (
        <div key={ref.user_id} className="table__item">
            <div className="item"><span>Место:</span> {ref.place}</div>
            <div className="item"><span>Имя:</span> <NavLink to={`/user/${ref.user_id}`}>{ref.name}</NavLink> </div>
            <div className="item"><span>Количество рефералов:</span> {ref.referral_count}</div>
        </div>
    ));

    return (
        <div className="user-data">
            <div className="user-data__wrapper">
                <div className="user-data__container">
                    <div className="item"><span>Количество приглашенных:</span> {statistic.refs_count}</div>
                    <div className="item"><span>Количество пользователей:</span> {statistic.users_count}</div>
                    <div className="item"><span>Количество выполненных заданий:</span> {statistic.complite_tasks_count}</div>
                    <div className="item"><span>Количество просмотров на уроки:</span> {statistic.comlite_lessons_count}</div>
                </div>
            </div>
            <div className="filter-buttons">
                <button 
                    className={`tab ${activeTab === 'topRefs' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('topRefs')}
                >Топ рефоводов</button>
                <button 
                    className={`tab ${activeTab === 'topPoints' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('topPoints')}
                >Топ по очкам</button>
            </div>
            <div className="filter-checkbox">За месяц: <input type="checkbox" checked={monthChecked} onChange={handleMonthCheck}/></div>
            {activeTab === 'topRefs' && (
                <UserDataList list={topRefsList} title="Топ рефоводов" ifNullText="Список пуст"/>
            )}
            {activeTab === 'topPoints' && (
                <UserDataList list={topPointsList} title="Топ по очкам" ifNullText="Список пуст"/>
            )}

            <button onClick={() => activeTab === 'topRefs' ? handleCreateList('рефералам', topRefs) : handleCreateList('очкам', topPoints)}>Создать список</button>
        </div>
    );
}

export default Statistic;
