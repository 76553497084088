import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import useHttp from "../../hooks/useHttp";
import UserList from "../../widgets/User/UserList/UserList";

const TaskUsers = () => {
    const { id } = useParams();
    const { fetchData, isLoading, error } = useHttp();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchData(`/api/task/${id}/users`, null, 'GET')
            .then(res => setUsers(res))
            .catch(error => console.error("Error fetch task users:", error));
    }, [fetchData, id])

    return (
        <div className="task-users__wrapper">
            <h1>Список пользователей, выполнившие задание:</h1>
            <UserList users={users} isLoading={isLoading} error={error}/>
        </div>
    );  
}

export default TaskUsers;