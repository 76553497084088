import { useCallback, useState } from 'react';

const _api = '';

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async (api, data = null, method = 'GET', headers = true) => {
        setIsLoading(true);
        try {
            let requestOptions;

            if (headers) {
                requestOptions = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: data ? JSON.stringify(data) : null
                };
            } else {
                requestOptions = {
                    method: method,
                    body: data
                };
            }

            const res = await fetch(_api + api, requestOptions);
            const responseData = await res.json();

            if (!res.ok) {
                throw new Error(responseData.error || 'Что-то пошло не так');
            }

            return responseData;
        } catch (error) {
            setError(error.message);
            return [];
        } finally {
            setIsLoading(false);
        }
    }, [])

    return { isLoading, error, fetchData };
};

export default useHttp;
