import { useState, useEffect, useCallback } from 'react';
import useHttp from './useHttp';

const useEntity = (entityName, endpoint, photoUpload = false) => {
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [popapId, setPopapId] = useState(0);
    const { fetchData, isLoading, error } = useHttp();

    const fetchItems = useCallback(() => {
        fetchData(`/api/${endpoint}`, null, 'GET')
            .then(res => setItems(res))
            .catch(error => console.error(`Error fetching ${entityName}:`, error));
    }, [endpoint, entityName, fetchData])

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const addItemHandler = async (data) => {
        if (photoUpload && data.image) {
            const formData = new FormData();
            formData.append('image', data.image.file);
            try {
                const result = await fetchData(`/api/${endpoint}/photo`, formData, 'POST', false);
                data.path = result.image;
            } catch (error) {
                console.error(`Error uploading image for ${entityName}:`, error);
                return;
            }
        }

        fetchData(`/api/${endpoint}/add`, data, 'POST')
            .then(() => fetchItems())
            .catch(error => console.error(`Error adding ${entityName}:`, error));
    };

    const deleteItemHandler = () => {
        fetchData(`/api/${endpoint}/delete`, { id: popapId }, 'DELETE')
            .then(() => fetchItems())
            .then(() => setPopapId(0))
            .catch(error => console.error(`Error deleting ${entityName}:`, error));
    };

    const hideItemHandler = (itemId) => {
        fetchData(`/api/${endpoint}/delete`, { id: itemId, is_deleted: true }, 'POST')
            .then(() => fetchItems())
            .catch(error => console.error(`Error hiding ${entityName}:`, error));
    };

    const returnItemHandler = (itemId) => {
        fetchData(`/api/${endpoint}/delete`, { id: itemId, is_deleted: false }, 'POST')
            .then(() => fetchItems())
            .catch(error => console.error(`Error returning ${entityName}:`, error));
    }

    const openPopapHandler = (id) => setPopapId(id);
    const closePopapHandler = () => setPopapId(0);

    return {
        items,
        filter,
        setFilter,
        isLoading,
        error,
        addItemHandler,
        deleteItemHandler,
        hideItemHandler,
        returnItemHandler,
        openPopapHandler,
        closePopapHandler,
        popapId,
    };
};

export default useEntity;
