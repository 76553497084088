import { useCallback, useState } from "react";
import './Search.css';

const Search = ({ setUsers, fetchData }) => {
    const [timer, setTimer] = useState(null);

    const handleSearch = useCallback(async (e) => {
        clearTimeout(timer);
        const search = e.target.value;

        const newTimer = setTimeout(async () => {
            try {
                let url = '';
                
                if (search.trim() !== '') {
                    url = `/api/users/search?query=${search}`;
                } else {
                    url = '/api/users/';
                }

                const res = await fetchData(url, null, "GET");
                setUsers(res);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        }, 300);

        setTimer(newTimer);
    }, [timer, fetchData, setUsers]);

    return (
        <input 
            className="search-input" 
            placeholder="Поиск по имени" 
            onChange={(e) => handleSearch(e)} 
        />
    );
}

export default Search;
