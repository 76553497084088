import { useEffect, useState } from 'react';
import useHttp from '../../hooks/useHttp';
import Popap from '../../UI/Popap/Popap';

const ChoosePopup = ({ id, handleClosePopap, closePopap }) => {
    const [lists, setLists] = useState([]);
    const { fetchData } = useHttp();

    useEffect(() => {
        fetchData(`/api/lists/user/${id}`, null, 'GET')
            .then(res => setLists(res))
            .catch(error => console.error('Error fetching lists: ', error));
    }, [fetchData, id])

    const handleAddToList = (listId) => {
        fetchData(`/api/list/${listId}/add`, {userId: id}, 'POST')
            .then(() => closePopap())
            .catch(error => console.error('Error adding user to list: ', error));
    }

    const listsElements = lists.map(list => (
        <li key={list.id} onClick={() => handleAddToList(list.id)}>
            {list.name}
        </li>
    ))

    return (
        <Popap handleClosePopap={handleClosePopap}>
            <ul>
                {lists.length > 0 ? listsElements : <div>Свободных списков нету!</div>}
            </ul>
        </Popap>
    );
};

export default ChoosePopup;
