import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import Loader from '../../UI/Loader/Loader';
import Error from '../../UI/Error/Error';

import useHttp from '../../hooks/useHttp';

const TaskDataPage = () => {
    const { id } = useParams();
    const { isLoading, error, fetchData } = useHttp();
    const [task, setTask] = useState({});

    useEffect(() => {
        fetchData(`/api/task/${id}`, null, 'GET')
            .then(res => res.length > 0 ? setTask(res[0]) : setTask({}))
            .catch(error => console.error("Error fetching task:", error));
    }, [id, fetchData]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTask({ ...task, [name]: value });
    };

    const handleSaveData = (e) => {
        e.preventDefault();
        fetchData(`/api/task/${id}`, task, 'PUT')
            .catch(error => console.error("Error updating task:", error));
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    return (
        <div className="data-page">
            <h1>Информация о задании:</h1>
            <div className="element-detail">
                <div className="item"><strong>ID:</strong> {task.id}</div>
                <div className="item">
                    <label><strong>Название:</strong></label>
                    <input type="text" name="name" value={task.name || ''} onChange={handleInputChange} />
                </div>
                <div className="item">
                    <label><strong>Текст:</strong></label>
                    <textarea name="text" value={task.text || ''} onChange={handleInputChange}></textarea>
                </div>
                <div className="item">
                    <label><strong>Количество очков:</strong></label>
                    <input type="text" name="points" value={task.points || ''} onChange={handleInputChange} />
                </div>
                <div className="item"><strong>Выполнило пользователей: </strong>{task.count}(<NavLink to={`/task/${task.id}/users`}>Открыть</NavLink>)</div>
                <div className="item"><strong>Статус:</strong> {task.is_deleted ? 'Удален' : 'Активен'}</div>
                <button onClick={handleSaveData}>Сохранить</button>
            </div>
        </div>
    );
};

export default TaskDataPage;
