import User from "../../../entities/User/User";
import Loader from "../../../UI/Loader/Loader";
import Error from "../../../UI/Error/Error";

const UserList = ({isLoading, error, users, handleDeleteFromList}) => {
    const userList = users.map(({ id, name }) => <User key={id} id={id} name={name} handleDeleteFromList={handleDeleteFromList}/>)

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    if (users.length === 0) {
        return <div className="user-list__container">Пользователей нет</div>;
    }

    return (
        <div className="user-list__container">
            {userList}
        </div>
    )
}

export default UserList;