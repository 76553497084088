import { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import Loader from "../../UI/Loader/Loader";
import Error from "../../UI/Error/Error";

import './WalletList.css'

const WalletList = () => {
    const { fetchData, isLoading, error } = useHttp();
    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        fetchData('/api/wallets', null, 'GET')
            .then(res => setWallets(res))
            .catch(error => console.error("Error fetching wallets:", error));
    }, [fetchData])

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    if (wallets.length === 0) {
        return <div className="user-list__container">Список кошельков пуст.</div>;
    }

    const walletsList = wallets.map(({wallet}, i) => <div className="item" key={i}>{wallet}</div>);

    return (
        <div className="wallet-list__wrapper">
            <h1>Список кошельков: </h1>
            <div className="walltes__container">
                {walletsList}
            </div>
        </div>
    )
}

export default WalletList;